import {Box, Typography, useMediaQuery} from '@material-ui/core';
import React, {Suspense, useContext, useEffect} from 'react';
import {Context} from '../../Context';
import LoadingStep from '../LoadingStep';
import {SuspenseImg} from '../SuspenseImg';
import {useLocation} from "react-router-dom";

const AppLayout = ({children}) => {
    const tablet = useMediaQuery('(min-width: 600px)');
    const desktop = useMediaQuery('(min-width: 1200px)');
    const {desktopImg, isHome, setIsHome} = useContext(Context);
    const location = useLocation();
    useEffect(() => {
        if (location.pathname !== '/') {
            setIsHome(false);
        }
    }, []);
    if (desktop) {
        return (
            <Box width="100%" display="flex">
                <Box style={{background: '#fff'}} minHeight="100vh" width="55vw">
                    <Suspense fallback={<LoadingStep/>}>
                        <SuspenseImg src={desktopImg}/>
                    </Suspense>
                </Box>
                <Box minHeight="100vh" width="45vw" position="relative">
                    {children}
                    <Box color={isHome ? '#fff' : '#000'} alignItems="center" justifyContent="center" display="flex"
                         position="absolute" bottom="1px" width="100%" padding="5px" zIndex={100}>
                        <Typography style={{fontSize: 12}}>
                            Powered by © <a style={isHome ? {color: '#fff'} : {color: '#000'}} href="https://bio.credit"
                                            target="_blank">BioCredit Colombia</a>. todos los derechos
                            reservados 2021
                        </Typography>
                    </Box>
                </Box>
            </Box>);
    }

    if (tablet) {
        return <Box width="100%">
            {children}
            <Box color={isHome ? '#fff' : '#000'} alignItems="center" justifyContent="center" display="flex"
                 position="absolute" bottom="1px" width="100%" padding="5px" zIndex={100}>
                <Typography style={{fontSize: 12}}>
                    Powered by © <a style={isHome ? {color: '#fff'} : {color: '#4f4f4f'}} href="https://bio.credit"
                                    target="_blank">BioCredit Colombia</a>. todos los derechos
                    reservados 2021
                </Typography>
            </Box>
        </Box>;
    }

    return <Box width="100%" position="relative" style={isHome ? {background: 'rgb(84 96 220)', overflowX: 'hidden'} : {}}>
        {children}
        {<Box textAlign="center" position="relative" color={isHome ? '#fff' : '#000'} alignItems="center" justifyContent="center" display="block"
               padding="5px" zIndex={100}>
            <Typography style={{fontSize: 11}}>
                Powered by © <a style={isHome ? {color: '#fff'} : {color: '#4f4f4f'}} href="https://bio.credit"
                                target="_blank">BioCredit Colombia</a>. todos los derechos
                reservados 2021
            </Typography>
        </Box>}
    </Box>;
};

export default AppLayout;
