import {
  Box,
  Button,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import React from 'react';
// import greenMark from '../../assets/images/greenMark.png';
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import {DotButton, useDotButton} from './CarouselDots'
import './embla.css'

import img01 from '../../assets/images/slider/01-700X1000_Mod.png';
import img02 from '../../assets/images/slider/02-700X1000_Mod.png';
import img03 from '../../assets/images/slider/03-700X1000_Mod.png';

const slides = Array.from(Array(5).keys())

const images = [
    img01,
    img02,
    img03,
  ]

const useStyles = makeStyles(() => ({
  img: {
    width: '100%',
    maxHeight: 300,
    objectFit: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    objectPosition: 'right',
    zIndex: 0,
  },
  lowEnd: {
    width: '100%',
    maxHeight: 200,
    height: 200,
    objectFit: 'contain',
    position: 'absolute',
    bottom: 0,
    right: 0,
    objectPosition: 'right',
    zIndex: 0,
  },

    text: {
        fontSize: 30,
        color: '#fff',
        fontFamily: 'App-bold',
        lineHeight: 1,
    },

    description: {
        fontSize: 14,
        color: '#fff',
        fontFamily: 'App-regular',
        marginBottom: 20,
    },
    step: {
        fontSize: 14,
        color: '#fff',
        fontFamily: 'App-semi-bold',
        zIndex: 1,
    },

    button: {
        textTransform: 'capitalize',
        color: '#5d62f1',
        background: '#fff !important',
        boxShadow: '0 3px 6px #66788033',
        borderRadius: 120,
        width: 250,
        '& .MuiButton-label': {
            fontSize: 13,
            fontFamily: 'App-bold',
            letterSpacing: '5px',
        },
        height: 42,
    },
}));

const steps = [
  'Verificamos su identidad.',
  'Actualiza la información de su comercio.',
  'Elige la cuenta en la que quiere recibir su plata.',
  'firma los términos y condiciones',
];

export default function Splash({ setShowSplash }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({loop: true}, [Autoplay()])
  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi)
  useMediaQuery('(max-height: 568px)');
  useMediaQuery('(min-width: 768px)');
  const classes = useStyles();

  return (
    <Box
      style={{ background: 'rgb(84 96 220)', overflowX: 'hidden' }}
      display="flex"
      minHeight="100vh"
      width="100%"
      flexDirection="column"
    >
      <Box
        padding="0"
        // marginTop="-50px"
        paddingBottom="30px"
        minHeight="100vh"
        zIndex={1}
        flex={1}
      >
        <section className="embla">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {images.map((img) => (
                <div className="embla__slide" key={img}>
                  <div className="embla__slide__number">
                    <div style={{backgroundImage: `url(${img})`}}></div>
                  </div>
                </div>
              ))}
            </div>
            <div className="embla__controls">
              <div className="embla__dots">
                {scrollSnaps.map((_, index) => (
                  <DotButton
                    key={index}
                    onClick={() => onDotButtonClick(index)}
                    className={'embla__dot'.concat(
                      index === selectedIndex ? ' embla__dot--selected' : ''
                    )}
                  />
                ))}
              </div>
            </div>
          </div>
        </section>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          marginTop="10px"
          zIndex={99}
          position="relative"
        >
          <Button
            className={classes.button}
            onClick={() => setShowSplash(false)}
          >
            CONTINUAR
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
